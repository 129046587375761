:root {
  --primary: #215732;
  --secondary: #444444;
  --white: #ffffff;
  --green: #1aa00b;
  --green-lighter: #c1dfc9;
  --green-lightest: #f2f8f4;
  --red: #e02020;
  --red-lightest: #ffe9e6;
  --orange: #e06950;
  --orange-lightest: #f7dad4;
  --yellow: #e49402;
  --yellow-lightest: #feedcd;
  --purple: #9d2f6d;
  --purple-lightest: #f3d8e7;
  --blue: #1e69c8;
  --blue-lightest: #cfe3f3;
  --grey-lightest: #f9f9f9;
  --grey-lighter: #eeeeee;
  --grey-light: #e5e5e5;
  --grey: #dadada;
  --grey-disabled: #f5f5f5;
  --grey-dark: #cccccc;
  --grey-darker: #808080;
  --grey-darkest: #595959;
  --black: #000000;
  --black-light: #14341e;
  --black-lightest: #444444;
  --cyan: #008192;
  --cyan-lightest: #c2eef5;
  --cyan-darkest: #065538;
}
