/* @font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-DemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
} */

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
