@tailwind preflight;
@tailwind components;
@tailwind utilities;

@import './root.css';
@import './fonts.css';
@import './common.css';
@import './ant-override.css';
@import './nprogress.css';

html,
body {
  font-family: 'Avenir Next', Arial, Helvetica, sans-serif;
}
